video.fullscreen {
    position: absolute;
    z-index: 0;
    object-fit: cover;
    width:100%;
    height:100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  
  }
  
  .container {
       /* position: relative; */
       display: flex;
       /* place-items: center; */
       height: 100vh;
       /* width: 100vw; */
       /* margin: 0 auto; */
       background: linear-gradient(to bottom, #002d47, #011c2c);
  }
 
  .content {
    z-index: 1;
  }
  
  /* ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: #e9edf4b7;
  } */
  
  /* li {
    float: left;
  } */
  
  li a {
    text-decoration: none;
  }
  
  /* li a:hover:not(.active) {
    background-color: #007ad9;
  } */
  /* .card {
    max-height: 350px;
    min-height: 300px;
    width: 250px;
    margin-right: 25px;
    margin-left: 25px;
    margin-top: 40px;
    margin-bottom: 20px;
    border-style: solid;
    border-width: 0px 0px 0px 0px;
    padding: 15px 25px 15px 25px;
    box-shadow: 0px 2px 8px 0px rgba(155, 155, 155, 0.49);
    background-color: #E6E8F4;
    border-radius: 60px 0px 60px 0px;
    opacity: 0.88; 
  } */

  .right{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
  }
  .right > p{
    color:#f1f1f1;
    font-size:2rem;
    margin-bottom: 15px;
    margin-bottom: 4rem;
  }
  @media (max-width:768px) {
    .right > p{
      font-size: 1rem;
    }
  }