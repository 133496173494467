.tracks-container{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    float: right;
    margin-top: 3px;
    width: 100%;
}
.tracks-container > input {
    border-radius: 36px;
    height: 2.3rem;
    width: 60%;
}
.tracks-container  > span{
    height: 2rem;
    margin: auto;
}
.tracks-container > button {
    border-radius: 24px;
    background: #3c4ef7;
}
.tracks-label{
    margin: auto;
    color: black;
    font-weight: 400;
    font-size: 14px;
    text-decoration: none;
    line-height: 45px;
}
.tracks-dropdown{
    border-radius: 10px;
    width: 100%;
    color: black;
    text-decoration: none;
    height: 2.1rem;
    font-size: 15px;
    font-weight: 300;
    margin-top: 10px;
}
.p-dropdown-panel .p-dropdown-header {
    padding: 0.429rem 1.857rem !important;
    border-radius: 15px;
}
.show-tracks{
    padding: 15px;
    border: 1px solid #8080802e;
}
.p-buttonset{
    margin-right:10px;
}
.tracks-input{
    height: 2rem;
    width: 75%;
    margin-left: 5px;
    margin: auto;
    border-radius: 16px;
    margin-left: 2px;
}

.icons-tracks{
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: baseline;
    padding: 10px;
}

.icons-tracks > i {
    margin: 15px;
}
.icons-tracks >p {
    margin-right:45px
}
.show-tracks> i {
    cursor: pointer;
}
.p-panel.p-panel-toggleable .p-panel-header {
    border: 1px solid #dee2e6;
    padding: 1.25rem;
    background: #f8f9fa;
    color: #343a40;
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;
}
.monitor-search-term{
    width: 100% !important;
    border-radius: 20px;
    font-size: 15px;
    font-weight: 300;
    margin-top: 10px;
    text-align: center;
}
.monitor-search-icons{
    display: flex;
    justify-content: space-between;
    padding: 10px;
    cursor: pointer;
    font-size: 12px;
    color: #7c8287;
}
.custom-target-icon{
    cursor: pointer;
    height: 2.7rem;
    margin-top: -12px
}
.img-icons{
    height: 1.5rem;
    margin-top: -3px;
}

.notifications-options{
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}
.notifications-options > i {
    margin: 10px;
}
.table{
    width: 100%;
}
.labels{
    margin: 15px;
    font-size: 14px;
    font-weight: 400;
}
.input{
    border-radius: 20px;
    width: 18rem;
    margin-bottom: 15px;
}
.button{
    border-radius: 20px;
    background: #575a5c !important;
    cursor: pointer;
}
.button:hover {
    background: #575a5c;
}
.button > span{
    font-size: 14px;
    font-weight: 400;
    border-radius: 20px;
    
}
.p-dialog-title{
    margin-right: 15px;
    margin-right: 15px;
    font-size: 15px;
    font-weight: 400;
}
.p-dialog .p-dialog-header {
    background: #f2f5f7 !important;
    height: 1rem !important;
    color: black;
    font-weight: 500;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled {
    color: #5ab7ff;
    margin-top: -12px !important;
}
.notification-popups{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.notification-popups > p,i {
    /* margin: 10px; */
}

.button-set{
    width: 100%;
    margin-top: 15px;
}
.button-set >span >button{
    background: #c3b5b5;
    border: none;
}

@media (max-width:768px) {
    .closeButton{
        display: flex;
    }
}
.closeButton{
    display: flex;
    justify-content: inherit;
    bottom: 0;
    position: fixed;
    margin: 16px 6px;
    border-radius: 50%;
}
.closeButton >button{
    border-radius: 50%;
}

.track-container-title{
    margin: 18px;
}

.pi{
    cursor: pointer;
}

.track-control{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.inline-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.filter-button-one{
    border-radius: 23px;
    line-height: 5px;
}
.p-panel .p-panel-header .p-panel-header-icon {
    color: #3c4ef7;
}
.slider-input{
    width: 4rem !important;
    border-radius: 10px;
    margin: 5px;
}