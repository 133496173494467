/* test remove */
.center {
  margin: auto;
  width: 50%;
  border: 3px solid #f4f9f4;
  padding: 10px;
}

.charts-container{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 35px;
}


@media (max-width:768px) {
  .p-dialog .p-dialog-header .p-dialog-title {
    font-size: 13px !important;
    font-weight: 300 !important;
  }
  .p-dialog .p-dialog-header {
    height: 1.5rem;
  }
  .p-dialog .p-dialog-header .p-dialog-header-icon {
    color: #ffffff;
    margin-top: -1rem;
  }
  .p-dialog .p-dialog-content{
    font-size: 13px;
  }
  .p-dialog{
        width: 98vw !important;
        margin: auto !important;
        position: fixed !important;
        left: 0px !important;
        top: 120.267px !important;
        right: 0px !important;
  }
}
.p-link:focus{
  box-shadow: none;
}
.p-datepicker table td > span {
  width: 1.5rem !important;
  height: 1.5rem !important;
  border-radius: 3px;
  transition: background-color 0.2s, box-shadow 0.2s;
  border: 1px solid transparent;
  background: none;
  margin: 4px;
  font-size: 10px;
}
.p-datepicker table td > span.p-highlight {
  color: #ffffff;
  background: #59a8e6 !important;
  border-radius: 50%;
  font-size: 10px;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: none !important;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none !important;
}

.p-dialog .p-dialog-header {
  border-bottom:none !important;
  
}
.p-confirm-dialog-reject{
  display: none !important;
}
/* .p-confirm-dialog-accept{
  display: none !important;
} */
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  color: #ffffff;
  background: #3144f7 !important;
}
.p-dropdown-panel {
  position: absolute;
  margin-top: 5px;
  border-radius: 15px;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 1rem;
  color: #1a2695;
  font-weight: 500;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
  color: #1f2a95;
  font-size: 1rem;
  font-weight: 300;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #116fbf;
  background: #234ce04d;
}

.p-chip img {
  border-radius: 50%;
  background: #b1acac !important; 
  padding: 4px !important;
}
.p-chip .p-chip-text{
  /* color: blue; */
} 

.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 550;
  font-size: 0.8rem;
  color: blue;
  display: flex;
  justify-content: space-between;
}

/* welcome page */

.welcome-page{
  margin: auto;
  width: 50%;
  padding: 10px;
  text-align: center;
  top: 0;
  bottom: 0;
  margin-top: 5rem;
  font-size: 1.5rem;
  border: 1px solid #80808026;
  border-radius: 10px;
}
.welcome-page >img{
  width: 10rem;
  margin-top: 2rem;
}