.car-icon{
    cursor: pointer;
}
.popup-container{
    position: inherit;
}
.mapboxgl-popup-content{
    width: 20rem;
    border-radius: 10px;
}
.mapboxgl-popup-content:hover {
    border: 1px solid #263af780;
}
.popup-header{
    display: flex;
    box-shadow: 0px 4px 4px 0px #263af780;
    border-radius: 5px;    
}
.popup-header >img {
    width: 25px;
    margin: 5px;
}

.popup-header >p{
    font-size: 16px;
    font-weight: 300;
    color: #3447f7;
    text-transform: capitalize;
    font-size: 0.9rem;
    margin: auto 0;
}
.popup-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.popup-content{

    font-weight: 300;
    color: #3447f7;
    text-transform: capitalize;
    font-size: 0.8rem;
}
.popup-content > img{
    border: 1px solid;
    padding: 5px;
    border: 1px solid #007ad9;
    padding: 5px;
    border-radius: 50%;
}
td, th {
    /* border-radius: 9px;
    border: 1px solid #dededf;
    text-align: center;
    padding: 8px;
    margin: 1px; */

  }
  
  tr:nth-child(even) {
    /* background-color: #dddddd; */
  }
  .floating-menu{
    position: absolute;
    right: 0;
    margin: 3px;
    font-size: 13px;
    line-height: 2;
    color: white;
    text-transform: capitalize;
    outline: none;
    border-radius: 15px;
    height: 3rem !important;
    height: -moz-fit-content;
    height: fit-content;
    z-index: 1000;
    bottom: 0;
    width: 3rem;
  }

  .p-speeddial-button.p-button.p-button-icon-only {
    width: 2rem !important;
    height: 2rem !important;
}

.notification-badge{
    z-index: 100000;
    position: relative;
    background: red;
}
td{
    color: #007ad9;
    font-size: 0.9rem;
    font-size: 300;
}
.mapboxgl-popup-close-button{
    padding: 2px 8px !important;
    background: white !important;
    margin: 12px !important;
    border-radius: 30% !important;
    border: none !important;
    font-size: 24px !important;
}
.popup-target-icon{
    cursor: pointer;
    height: 2.7rem;
    margin-top: -4px;
    margin-left: 69px;
}
.p-speeddial-action {
    width: 2rem;
    height: 2rem;
    background: white;
    color:#a9a9a9;;
}

@keyframes pulsate {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .selected-marker {
    animation: pulsate 2s infinite;
  }
.mapbox-gl-draw_point {
    background-image: url('./../../assets/GoefenceIcon.png') !important;
    height: 1rem;
}