.header-container{
   display: flex;
   justify-content: space-between;
   height: 3rem;
   background: #5a626f;
   color: white;
   text-align: center;
   border-bottom: 2px solid;
   padding-top: 0px;
}
.active-links{
    background: #007ad9a6; 
    color: black !important;
    font-weight: 500;

 }
 .link{
   color: black;
   font-weight: 300;
 }

 .header-item-menu{
    margin: auto;
    display: flex;
    justify-content: space-between;
 }
 .header-items{
   display: flex;
   height: 3.4rem;
   justify-content: flex-end;
 }.header-items >a {
   color: black;
   font-weight: 400;
   font-size: 14px;
   text-decoration: none;
   margin: auto;
 }
 .logo{
    padding-left: 15px;
    font-weight: 500;
    font-size: 16px;
 }
 .user{
    padding-right: 15px;
    font-weight: 500;
    font-size: 15px;
    display: flex;
 }
 .header-eclipse{
   color: #e7dcdc;
   margin: auto;
   background: blue;
   padding: 5px;
   margin: 1px;
   font-weight: 800;
 }
 .header-logo{
    width: 5rem;
    margin: 3px;
    position: absolute;
    left: 0;
    padding: 3px;
 }
 .items{
   display: flex;
   /* justify-content: center; */
   height: 3.4rem;
 }

